window.addEventListener('load', function(){
  const form = document.getElementById("save-card-form")
  if(form != null) {

    // Create a Stripe client.
    const stripe = Stripe(process.env.STRIPE_PUBLIC_KEY)

    const saveCardButton = document.getElementById('save-card')
    const cardTokenInput = document.getElementById('card-token')

    let createTokenAndSaveCard = async() => {
      // クレジットカードのトークンを取得
      const result = await stripe.createToken(cardNumber);

      if (result.error) {
        let message = result.error.message
        if (result.error.code === 'card_declined') {
          message = message + '<br>' + 'お手数ですが、他のカードをお試しください。'
        }
        bulmaToast.toast({
          message: message,
          type: "is-danger",
          position: "top-right",
          dismissible: true,
          duration: 6000,
          animate: { in: "slideInDown", out: "fadeOut" }
        });
      } else {
        // カード情報を設定
        cardTokenInput.value = result['token']['id'];
        form.submit()
      }
    };

    // Create an instance of Elements.
    let elements = stripe.elements();

    // Custom styling can be passed to options when creating an Element.
    var elementStyles = {
      base: {
        color: '#343638',
        fontWeight: 300,
        fontFamily: 'Noto Sans JP, Hiragino Kaku Gothic ProN, YuGothic, Meiryo, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: '#9BACC8',
        },

        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
      invalid: {
        color: '#F14668',
        ':focus': {
          color: '#FA755A',
        },
      },
    };

    var elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    var cardNumber = elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardNumber.mount('#card-number');

    var cardExpiry = elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardExpiry.mount('#card-expiry');

    var cardCvc = elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    cardCvc.mount('#card-cvc');

    // Create an instance of the card Element.
    // const card = elements.create('card', { style: style })

    // Handle real-time validation errors from the card Element.
    // card.addEventListener('change', showCardError);

    saveCardButton.addEventListener('click', (e) => {
      e.preventDefault();
      createTokenAndSaveCard();
    });
  }
});