window.addEventListener('load', () => {
    if (document.getElementById('contact-email') == null) {
        return;
    }
    setTimeout(() => {
        let email = '&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;';
        email = email + '&#114;&#111;&#99;&#107;&#45;&#116;&#114;';
        email = email + '&#105;&#112;&#46;&#99;&#111;&#109;';
        document.getElementById('contact-email').innerHTML = email;
    }, 100)
})