window.onload = function(){
  const modal = document.getElementById('sample-image-modal')
  const openButtons = document.getElementsByClassName('sample-modal-open')
  const backGround = document.getElementById('modal-background')
  const closeButton = document.getElementById('modal-close')
  let content

  const show = (() => {
    if (modal === null) {
      return
    }
    modal.appendChild(content)
    content.style.display = 'flex'
    modal.classList.add('is-active')
  })

  const hide = (() => {
    if (modal === null) {
      return
    }
    content.style.display = 'none'
    modal.classList.remove('is-active')
  })

  for(i=0;i<openButtons.length;i++) {
    openButtons[i].addEventListener('click', (e) => {
      if (e.currentTarget.getAttribute('data-topo-id')) {
        content = document.getElementById('sample-images-' + e.currentTarget.getAttribute('data-topo-id'))
      } else {
        content = document.getElementById('sample-images')
      }
      show()
    })
  }

  if (backGround !== null) {
    backGround.addEventListener('click', () => {
      hide()
    })
  }

  if (closeButton !== null) {
    closeButton.addEventListener('click', () => {
      hide()
    })
  }
}