// js,css,images
import '../javascripts/application.js';
import '../stylesheets/application.scss';
require.context('../images', true, /\.(png|jpg|jpeg|svg)$/);

// ライブラリ
import 'bulma/css/bulma.css'
import 'animate.css/animate.css'

import * as bulmaToast from 'bulma-toast/dist/bulma-toast.min.js';
global.bulmaToast = bulmaToast;
